import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Scanner } from '@models/hardware/scanner';
import { FileUploadService } from '@services/utils/file-upload.service';
import { LocalStorageService } from '@services/storage/local-storage.service';

@Injectable()
export class UniversalRfidReaderInterfaceResource {
    constructor(
        private fileUploadService: FileUploadService,
        private http: HttpClient,
        private localStorageService: LocalStorageService
    ) {}

    private headers() {
        /* eslint-disable */
        return { Authorization: `${this.localStorageService.get('uat')}` };
        /* eslint-enable */
    }

    scan(scanner: Scanner): Promise<any> {
        let scanUrl = `${scanner.value}/${scanner.hardware_settings.scan_endpoint}`;
        let scanParams = this.scanParameters(scanner.hardware_settings);
        return this.http
            .post(scanUrl, scanParams, {
                headers: {
                    ...this.headers(),
                },
            })
            .toPromise();
    }

    firmwareVersionInfo(scannerUrl: String): Promise<any> {
        let fwInfoUrlExt = 'status';
        return this.http.get(`${scannerUrl}/${fwInfoUrlExt}`).toPromise();
    }

    getFirmwareConfig(scanner: Scanner) {
        let url = `${scanner.value}/api/v1/firmware`;
        return this.http.get(url).toPromise();
    }

    private scanParameters(hardwareSettings: any): any {
        let params = { read_time_config: {} };

        params['read_time_config']['read_duration'] = !!hardwareSettings.scan_duration
            ? hardwareSettings.scan_duration
            : 4;
        params['read_time_config']['absolute_timeout'] = !!hardwareSettings.absolute_timeout
            ? hardwareSettings.absolute_timeout
            : 20;
        return params;
    }
}
