import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from '../config/configuration';
import { Message } from '@models/core/message';

export interface DashboardCounts {
    counts: {
        kits_with_recalled_count: number;
        bins_with_recalled_count: number;
        kits_with_expired_count: number;
        bins_with_expired_count: number;
        containers_with_expired_count: number;
        containers_with_recalled_count: number;
    };
    data: {
        kit_master_ids_with_expired: number;
    };
}

@Injectable()
export class DashboardResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    getCounts(): Promise<DashboardCounts> {
        return this.http.get<any>(`${this.API_ENDPOINT}dashboard/counts`).toPromise();
    }

    kitsNotScannedIn(data) {
        return this.http.get<any>(`${this.API_ENDPOINT}dashboard/kits_not_scanned_in`, { params: data }).toPromise();
    }

    getKitsNeedingArchiveMessage(): Promise<any> {
        return this.http
            .get<any>(`${this.API_ENDPOINT}dashboard/kits_archive_message`)
            .toPromise()
            .then((resp) => {
                if (!!resp) {
                    const kitArchiveMsg: Message = {
                        id: resp.kits_needing_archive_message.id,
                        chip_content: 'Kits to Review',
                        header: resp.kits_needing_archive_message.header,
                        body: resp.kits_needing_archive_message.body,
                        can_dismiss: true,
                        recall_message: false,
                        kits_archive_message: true,
                        published_at: resp.kits_needing_archive_message.created_at,
                        message_type: { name: 'info' },
                    };
                    return kitArchiveMsg;
                } else {
                    return null;
                }
            });
    }

    dismissKitsArchiveMessage(id) {
        let params = { message_id: id };
        return this.http.put<any>(`${this.API_ENDPOINT}dashboard/dismiss_kits_archive_message`, params).toPromise();
    }

    getExpiredItems(timeFrame, pricingType) {
        let data = { time_frame: timeFrame, pricing_type: pricingType };
        return this.http.get<any>(`${this.API_ENDPOINT}dashboard/expired_items`, { params: data }).toPromise();
    }
}
