import { Injectable } from '@angular/core';

import { ApplicationService } from '@services/system/application.service';
import { HardwareResource } from '@resources/hardware-resource.service';

import { IFirmwareRevision } from '@models/hardware/firmware-revisions';
import { Scanner } from '@models/hardware/scanner';
import { UniversalRfidReaderInterfaceResource } from '@resources/universal-rfid-reader-interface-resource.service';

@Injectable()
export class UniversalRfidReaderInterfaceService {
    constructor(
        private applicationService: ApplicationService,
        private hardwareResource: HardwareResource,
        private universalRfidReaderResource: UniversalRfidReaderInterfaceResource
    ) {}

    scan(scanner: Scanner): Promise<any> {
        return this.universalRfidReaderResource.scan(scanner).then((result) => {
            // pass just the tags array back for compatibility
            return result.data.tags;
        });
    }

    checkStatus(scanner: Scanner): Promise<any> {
        if (scanner.statusCheckPromise) {
            return scanner.statusCheckPromise;
        }

        scanner.loading = true;

        scanner.statusCheckPromise = this.setFirmwareRevisionsForScanner(scanner)
            .then((currentFirmwareRevisionsOnScanner: IFirmwareRevision[]) => {
                scanner.online = 'YES';
                return this.checkNeedsUpdate(scanner, currentFirmwareRevisionsOnScanner);
            })
            .then(() => {
                if (scanner.needsUpdate.length) {
                    scanner.online = 'UPGRADE';
                } else {
                    scanner.online = 'YES';
                }
            })
            .catch(() => {
                console.log(`${scanner.name}: device offline`);
                scanner.online = 'NO';
                scanner.needsUpdate = _.compact([
                    _.find(scanner.hardware_type_firmware_revisions, { name: 'universal-rfid-reader-interface' }),
                ]);
            })
            .finally(() => {
                scanner.loading = false;
                delete scanner.statusCheckPromise;
            });

        return scanner.statusCheckPromise;
    }

    updateHardwareConfig(scanner: Scanner) {
        return this.universalRfidReaderResource
            .getFirmwareConfig(scanner)
            .then((data) => {
                if (data) {
                    return this.hardwareResource.hardwareConfig(scanner.id, data);
                }
                return Promise.reject();
            })
            .catch((err) => {
                Promise.reject(err);
            });
    }

    private checkAlive(scannerUrl: string) {
        console.log(`Check ${scannerUrl} alive...`);
        return this.universalRfidReaderResource
            .firmwareVersionInfo(scannerUrl)
            .then((result) => {
                if (result) {
                    Promise.resolve(result);
                } else {
                    // if result is null
                    // CORS likely blocked error info
                    // so assume the scanner endpoint is not online
                    Promise.reject();
                }
            })
            .catch(() => {
                return new Promise((resolve) => setTimeout(resolve, 12500)).then(() => {
                    return this.checkAlive(scannerUrl);
                });
            });
    }

    private checkFirmwareVersions(scanner: Scanner): Promise<IFirmwareRevision[]> {
        let firmwareVersionPromises = [this.universalRfidReaderResource.firmwareVersionInfo(scanner.value)];

        return Promise.all(firmwareVersionPromises).then((firmwareVersionsFromScanner) => {
            let mappedFirmwareVersions = firmwareVersionsFromScanner.map((firmwareVs) => {
                return {
                    version: firmwareVs.version,
                    firmware_revision_type: firmwareVs.name,
                } as IFirmwareRevision;
            });
            return mappedFirmwareVersions;
        });
    }

    private setFirmwareRevisionsForScanner(scanner: Scanner): Promise<IFirmwareRevision[]> {
        let firmwareRevisions;
        return this.checkFirmwareVersions(scanner)
            .then((result) => {
                firmwareRevisions = result;
                // unfortunately these identical yet differently named arrays tie into existing scanner upgrade functionality
                scanner.firmware_revisions = result;
                scanner.firmwareRevisions = result;

                this.applicationService.suppressError = true; // suppress benign errors from user display
                return firmwareRevisions.length
                    ? this.hardwareResource.setFirmwareRevisions(scanner.id, result)
                    : Promise.resolve();
            })
            .then((): IFirmwareRevision[] => firmwareRevisions)
            .finally(() => {
                this.applicationService.suppressError = false;
            });
    }

    private checkNeedsUpdate(scanner, firmwareRevisions?) {
        const getFirmwareRevisions = firmwareRevisions
            ? Promise.resolve(firmwareRevisions)
            : this.setFirmwareRevisionsForScanner(scanner);

        return getFirmwareRevisions.then((revisions) => {
            firmwareRevisions = revisions;
            scanner.needsUpdate = [];
            scanner.hardware_type_firmware_revisions.forEach((revisionForType: IFirmwareRevision) => {
                const revisionOnScanner: IFirmwareRevision = firmwareRevisions.find(
                    (fr) => fr.firmware_revision_type === revisionForType.firmware_revision_type
                ) as IFirmwareRevision;

                if (!revisionOnScanner || revisionForType.version !== revisionOnScanner.version) {
                    scanner.needsUpdate.push(revisionForType);
                }
            });
        });
    }
}
