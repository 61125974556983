import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Segment } from '@models/core/segment';
import { OtherItemsRow } from '../container-other-items-table/container-other-items-table';
import { SegmentTemplateResource } from '@resources/segment-template-resource.service';

@Component({
    selector: 'container-add-to-segment',
    templateUrl: './container-add-to-segment.html',
    styleUrls: ['./container-add-to-segment.scss'],
})
export class ContainerAddToSegment {
    segmentId: number;

    constructor(
        private dialogRef: MatDialogRef<ContainerAddToSegment>,
        private segmentTemplateResource: SegmentTemplateResource,
        @Inject(MAT_DIALOG_DATA)
        protected data: { segments: Segment[]; formularyItem: OtherItemsRow; containerId: number }
    ) {}

    onCancel() {
        this.dialogRef.close();
    }

    submit() {
        const primarySegmentTemplate = this.data.segments
            .find((s) => s.id === this.segmentId)
            .segment_templates.find((st) => st.primary_segment_template);

        const currentFormularyItemIds = primarySegmentTemplate.formulary_items.map(
            ({ formulary_item_id }) => formulary_item_id
        );

        this.segmentTemplateResource
            .updateSegmentTemplate({
                id: primarySegmentTemplate.id,
                formulary_item_ids: [...currentFormularyItemIds, this.data.formularyItem.formulary_item_id],
            })
            .then(() => {
                this.dialogRef.close(true);
            });
    }
}
