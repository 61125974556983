import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ActionService } from '@services/utils/action.service';
import { DashboardCounts } from '@resources/dashboard-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { RecallResource } from '@resources/recall-resource.service';
import { ReportDataService } from '@services/core/report-data.service';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';

@Component({
    selector: 'expired-and-recalled',
    templateUrl: './expired-and-recalled.html',
    styleUrls: ['./expired-and-recalled.scss'],
})
export class ExpiredAndRecalled {
    expiredBins: number;
    recalledBins: number;
    expiredKits: number;
    recalledKits: number;
    expiredKitMasterIds;
    shelvedInventoryEnabled: boolean;
    recalls;
    allowAddRecallFromDashboard: boolean;
    expiredContainers: number;
    recalledContainers: number;
    containersEnabled: boolean;
    @Input() dashboardCounts: DashboardCounts;
    @Input() loading: boolean;

    constructor(
        private actionService: ActionService,
        private hospitalInfoService: HospitalInfoService,
        protected recallResource: RecallResource,
        private router: Router,
        private reportDataService: ReportDataService,
        private featureFlagService: FeatureFlagService
    ) {}

    ngOnInit() {
        this.shelvedInventoryEnabled = this.hospitalInfoService.allowShelvedInventory();
        this.featureFlagService.getFeatureValue('use-containers').subscribe((value) => {
            this.containersEnabled = value;
        });

        this.getRecalls();
        this.setupPermissions();
    }

    ngOnChanges() {
        this.setupCounts();
    }

    setupPermissions() {
        this.allowAddRecallFromDashboard = this.actionService.isAllowAction(
            'hospital_settings',
            'view_hospital_settings',
            'Add recall from Dashboard'
        );
    }

    setupCounts() {
        this.recalledKits = this.dashboardCounts?.counts?.kits_with_recalled_count;
        this.recalledBins = this.dashboardCounts?.counts?.bins_with_recalled_count;
        this.expiredKits = this.dashboardCounts?.counts?.kits_with_expired_count;
        this.expiredBins = this.dashboardCounts?.counts?.bins_with_expired_count;
        this.expiredKitMasterIds = this.dashboardCounts?.data?.kit_master_ids_with_expired;
        this.expiredContainers = this.dashboardCounts?.counts?.containers_with_expired_count;
        this.recalledContainers = this.dashboardCounts?.counts?.containers_with_recalled_count;
    }

    getRecalls() {
        this.recallResource.recallsList().then((data: any) => {
            this.recalls = data.recalls;
        });
    }

    goToExpirationsReport(asset: string) {
        // in the case of 'bin' an empty array of kit master ids is all that is needed
        let kitMasterIds;
        if (asset === 'kit') {
            kitMasterIds = this.expiredKitMasterIds.map((id) => {
                return { id: id };
            });
        } else {
            kitMasterIds = [];
        }
        this.reportDataService.setPreFilter({
            expiration: {
                daysExpiringIn: 1,
                includeExpired: true,
            },
            genericNdcLotSearch: {
                generic: {},
                lot: null,
                ndc: null,
                type: 'generic',
            },
            binKitMasters: {
                binKit: asset,
                kitMasters: kitMasterIds,
            },
        });
        this.router.navigate(['report/custom']);
    }

    goToRecallsReport(binKit: string) {
        this.reportDataService.setPreFilter({
            binKit: binKit,
            recalls: this.recalls,
        });
        this.router.navigate(['report/item-recalls']);
    }
}
