import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'expiring-chip',
    templateUrl: './expiring-chip.html',
    styleUrls: ['./expiring-chip.scss'],
})
export class ExpiringChip {
    @Input() expiration: string;
    @Input() expired?: boolean;
    @Input() itemExpiringSoon?: boolean;
    hasExpired: boolean;

    constructor() {}

    ngOnInit() {
        const expirationDateObj = moment(this.expiration);
        const today = moment(new Date());

        this.hasExpired = this.expired || expirationDateObj.diff(today, 'days') < 0;
    }

    getFormattedExpiration(expirationDate: string) {
        if (!moment(expirationDate).isValid()) {
            return '-';
        }

        const formattedDate = moment(expirationDate).format('MMM DD, YYYY');

        return formattedDate;
    }
}
