import { Component, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { DashboardService } from '@services/core/dashboard.service';
import { Message } from '@models/core/message';
import { FeatureFlagService } from '@services/system/feature-flag/feature-flag.service';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { DashboardCounts, DashboardResource } from '@resources/dashboard-resource.service';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.scss'],
})
export class Dashboard {
    dashboardMessages: Message[] = [];
    hasMessages: boolean = false;
    isMessageLoading: boolean = true;
    messageBoardHeight: number;
    containersEnabled: boolean;

    @ViewChildren('reportWidgets') reportWidgets: QueryList<ElementRef>;
    @ViewChildren('kitProcessingWidget') kitProcessingWidget: QueryList<ElementRef>;

    kitProcessingWidgetHeight: number;
    reportWidgetHeight: number;
    shelvedInventoryEnabled: boolean;
    dashboardCounts: DashboardCounts;
    dashboardCountsLoading: boolean = true;

    constructor(
        private dashboardService: DashboardService,
        private dashboardResource: DashboardResource,
        private featureFlagService: FeatureFlagService,
        private hospitalInfoService: HospitalInfoService
    ) {}

    get isEmptyExpiredRecalled() {
        if (
            !this.dashboardCounts?.counts?.bins_with_expired_count &&
            !this.dashboardCounts?.counts?.bins_with_recalled_count &&
            !this.dashboardCounts?.counts?.containers_with_expired_count &&
            !this.dashboardCounts?.counts?.containers_with_recalled_count &&
            !this.dashboardCounts?.counts?.kits_with_expired_count &&
            !this.dashboardCounts?.counts?.kits_with_recalled_count
        ) {
            return true;
        }

        return false;
    }

    ngOnInit() {
        this.shelvedInventoryEnabled = this.hospitalInfoService.allowShelvedInventory();
        this.featureFlagService.getFeatureValue('use-containers').subscribe((value) => {
            this.containersEnabled = value;
        });

        this.loadMessages();
        this.getCounts();
    }

    getCounts() {
        this.dashboardResource.getCounts().then((data) => {
            this.dashboardCounts = data;
            this.dashboardCountsLoading = false;
        });
    }

    loadMessages() {
        this.isMessageLoading = true;
        this.dashboardService.getDashboardMessages().then((messages: Message[]) => {
            this.dashboardMessages = messages;
            this.hasMessages = this.dashboardMessages?.length > 0;
            this.isMessageLoading = false;
        });
    }

    ngAfterViewInit() {
        combineLatest([
            this.kitProcessingWidget.changes.pipe(startWith(this.kitProcessingWidget)),
            this.reportWidgets.changes.pipe(startWith(this.reportWidgets)),
        ]).subscribe(([kitProcessingWidgetElement, reportWidgetElement]) => {
            const kitProcessingResizeObserver = new (window as any).ResizeObserver((entries) => {
                for (const entry of entries) {
                    this.kitProcessingWidgetHeight = entry.target.clientHeight;

                    if (!this.isMessageLoading) {
                        this.updateMessageBoardHeight();
                    }
                }
            });

            kitProcessingResizeObserver.observe(kitProcessingWidgetElement.first.nativeElement);

            // Observe reportWidgetElement
            const reportWidgetResizeObserver = new (window as any).ResizeObserver((entries) => {
                for (const entry of entries) {
                    this.reportWidgetHeight = entry.target.clientHeight;

                    if (!this.isMessageLoading) {
                        this.updateMessageBoardHeight();
                    }
                }
            });
            reportWidgetResizeObserver.observe(reportWidgetElement.first.nativeElement);
        });
    }

    updateMessageBoardHeight() {
        // Variables calculated based off css margins applied
        const kitProcessingOutterSpacing = 30;

        if (this.containersEnabled) {
            if (this.isEmptyExpiredRecalled && !this.hasMessages) {
                return;
            }

            if (!this.shelvedInventoryEnabled && !this.hasMessages) {
                this.messageBoardHeight = this.reportWidgetHeight;
                return;
            }

            if (this.shelvedInventoryEnabled && this.hasMessages) {
                this.messageBoardHeight = this.kitProcessingWidgetHeight;
                return;
            }
        }

        if (!this.containersEnabled && !this.hasMessages) {
            this.messageBoardHeight = this.reportWidgetHeight;
            return;
        }

        // Container is disabled and has messages
        this.messageBoardHeight = this.reportWidgetHeight + this.kitProcessingWidgetHeight + kitProcessingOutterSpacing;
    }
}
